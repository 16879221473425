.modal__background {
  height: 100%;
}

.modal__container {
  width: 100%;
  height: 100vh;
  background-color: #F1F2F3;
  background: url(../../images/Frame.svg) repeat;
}

.modal__content {
  background: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .modal__content {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

@media screen and (max-width: 768px) {
  .modal__content {
    align-items: normal;
  }
}
