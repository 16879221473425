.main {
  /* margin: 67px 90px 130px; */
  margin: 67px 90px auto;
  display: flex;
  flex-direction: column;
}

.main__img-lamp {
  position: absolute;
  display: none;
  right: -100%;
  top: -190px;
  width: 325px;
  height: 364px;
}

.main__title {
  max-width: 644px;
  font-family: Manrope;
  color: var(--primary-black);
  font-size: 54px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  margin-bottom: 80px;
  position: relative;
}

.main__title-span {
  color: var(--bg-text-gray);
  background: var(--primary-black);
  border-radius: 12px 12px 0 12px;
  line-height: 140%;
  font-size: 45px;
}

.main__line-first {
  width: 410px;
  position: absolute;
  left: 220px;
  top: 114px;
}

.main__line-second {
  width: 266px;
  position: absolute;
  bottom: -6px;
  left: 4px;
}

@media screen and (max-width: 1000px) {
  .main__img-lamp {
    display: inline;
    width: 79px;
    height: 86px;
    right: 20px;
    top: -40px;
  }
}

@media screen and (max-width: 820px) {
  .main__title {
    font-size: 36px;
    font-weight: 400;
    width: 353px;
  }
}

@media screen and (max-width: 820px) {
  .main__line-first {
    width: 260px;
    left: 35px;
    top: 117px;
  }
}

@media screen and (max-width: 820px) {
  .main__line-second {
    width: 183px;
    left: 0px;
    bottom: 35px;
  }
}

@media screen and (max-width: 768px) {
  .main {
    margin: 60px 20px 40px;
  }
}

@media screen and (max-width: 768px) {
  .main__title-span { 
    font-size: 36px;
  }
}

@media screen and (max-width: 600px) {
  .header__img-lamp {
    width: 79px;
    height: 86px;
    top: -20px;
    right: 20px;
  }
}

@media screen and (max-width: 500px) {
  .main__title {
    margin-bottom: 110px;
  }
}
