.card-idea__list {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(550px, 1fr)); */
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  /* position: relative;
  overflow: hidden; */
}

.card-idea__list-blur :nth-last-child(-n+2) {
  filter: blur(1.5px);
}

/* .card-idea__list ::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100px;
  z-index: 2; 
  background: linear-gradient(180deg, white 10%, transparent);
} */

/* .card-idea__gradient {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.575));
  z-index: 5;
} */

.card-idea__list-container {
  position: relative;
  margin-top: 110px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* .card-idea__list-background {
  position: absolute;
  height: 50%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  backdrop-filter: blur(50px);
  background: linear-gradient(89.5deg, rgba(0, 0, 0, 0.2) 2.06%, rgba(0, 0, 0, 0.044694) 39.98%, rgba(0, 0, 0, 0) 101.51%);
} */

.card-idea__nav {
  display: flex;
  gap: 16px;
}

.card-idea__type {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.card-idea__button { 
  width: 300px; 
  height: 56px; 
  border-radius: 0px 12px 12px 12px; 
  font-family: PT Mono; 
  font-size: 18px; 
  font-weight: 400; 
  line-height: 20.16px; 
  background-color: var(--primary-black); 
  color: var(--bg-text-gray); 
  margin: 40px auto 0; 
}

.card-idea__list-button {
  border: none;
  color: var(--primary-black);
  /* margin-top: 40px; */
  background: transparent;
  position: relative;
  opacity: 1;
  margin: 40px auto 0;
  width: 175px;
}
.card-idea__list-button:hover {
  opacity: 75%;
  transition: opacity 0.5s linear;
}

.card-idea__list-img {
  position: absolute;
  left: 0;
  bottom: -12px;
}

@media screen and (max-width: 768px) {
  .card-idea__list {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .card-idea__list-img {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .card-idea__list-button {
    padding: 0;
    height: 48px;
    color: white;
    border-radius: 12px 0px 12px 12px;
    background-color: var(--primary-black);
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .card-idea__list-button:hover {
    color: var(--bg-text-gray);
  }
}