.header {
  background-color: transparent;
  position: sticky;
  margin: 40px 90px 0;
  display: flex;
}

.header_mobile {
  background-color: #F1F2F3;
}

.header__nav {
  display: flex;
  height: 38px;
  justify-content: space-between;
  background-color: transparent;
}

.header__nav-logo {
  margin-bottom: -9px;
  padding-right: 36px;
  cursor: pointer;
}

.header__nav-list {
  display: flex;
  gap: 24px;
}

.header__nav-list_type_left {
  align-items: center;
}

.header__nav-list_type_right {
  position: absolute;
  right: 0;
}

.header__nav-item {
  color: rgba(47, 47, 55, 0.35);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 1;
  background-color: transparent;
}

.header__nav-item:hover {
  opacity: 75%;
  transition: opacity 1s linear;
  background-color: transparent;
}

.header__nav-item_active {
  color: var(--primary-black);
  background-color: transparent;
}

.header__nav-button {
  opacity: 1;
  padding: 0;
  height: 38px;
  border-radius: 8px;
  border: none;
  font-size: 18px;
  font-weight: 400;
  line-height: 20.16px;
  cursor: pointer;
}

.header__nav-button_type_background {
  background-color: var(--primary-color);
  color: var(--bg-text-gray);
  font-family: PT Mono;
  width: 160px;
}

.header__nav-button_type_transparent {
  color: var(--primary-color);
  font-family: Manrope;
  background-color: transparent;
  border: none;
}

.header__nav-button:hover {
  opacity: 80%;
  transition: opacity 0.4s linear;
}

.header__nav-button_type_transparent:hover {
  color: var(--primary-black);
}

.header__nav-back {
  color: rgba(47, 47, 55, 0.35);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.header__nav-profile {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: end;
  border: 1px solid var(--primary-black);
  border-radius: 8px;
  background-color: #F1F2F3;
  padding: 8px 12px;
  font-family: PT Mono;
  font-size: 18px;
  font-weight: 400;
  line-height: 20.16px;
  text-align: end;
  color: var(--primary-black);
  cursor: pointer;
  z-index: 0;
  width: max-content;
}

.header__nav-profile-item {
  width: 75px;
  z-index: 1;
  margin: 11px 0;
}

.header__nav-button-img {
  background: url(../../images/Vector719.svg) no-repeat;
  height: 10px;
  margin-top: -5px;
}

.header__nav-profile-dropdown {
  position: absolute;
  top: 15px;
  right: 10px;
}

.header__nav-profile-dropdown-up {
  transform: rotate(180deg);
}

.header__nav-profile-email {
  padding-right: 20px;
}

.header__profile-menu {
  background-color: transparent;
}

.header__profile-menu_mobile {
  display: inline;
  background-color: #f1f2f3;
  position: absolute;
  top: 65px;
  right: 0;
  width: 100%;
  box-shadow: none;
  padding: 0 8px 7px;
}

.header__profile-container {
  position: absolute;
  right: 0;
}

@media screen and (min-width: 768px) {
  .header__profile-menu_mobile {
    /* background-color: transparent; */
    top: 45px;
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .header__nav-back {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .header {
    padding: 20px 20px 0;
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  .header__nav-list {
    flex-direction: column;
    gap: 12px;
  }
}

@media screen and (max-width: 768px) {
  .header__nav-item {
    font-size: 20px;
  }
}

@media screen and (max-width: 768px) {
  .header__nav-back {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .header__nav-profile {
    align-items: start;
  }
}

@media screen and (max-width: 768px) {
  .header__nav-profile-item {
    text-align: left;
  }
}