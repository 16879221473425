.auth-modal {
    position: fixed;
    display: flex;
    visibility: hidden;
    opacity: 0;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    transition: visibility 0.2s, opacity 0.2s linear;
  }

  .auth-modal_opened {
    visibility: visible;
    opacity: 1;
    z-index: 3;
  }

  .auth-modal__form {
    width: 540px;
    padding-bottom: 36px;
    background: #ffffff;
    border-radius: 10px;
    padding: 30px 30px 40px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .auth-modal__container {
    position: relative;
  }

  .auth-modal__button-close {
    background-image: url(../../../images/cross.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 32px;
    height: 32px;
    position: absolute;
    right: 15px;
    top: 15px;
    background-color: transparent;
    border: 0;
    transition: opacity 0.5s linear;
  }
  
  .auth-modal__button-close:hover {
    opacity: 0.6;
    cursor: pointer;
  }

  .auth-modal__title {
    font-family: Manrope;
    font-size: 32px;
    font-weight: 400;
    line-height: 33.6px;
    margin-bottom: 30px;
  }

  .auth-modal__button {
    background-color: transparent;
    color: var(--primary-black);
    height: 56px;
    border-radius: 0px 12px 12px 12px;
    border: 1px solid var(--primary-black);
    padding: 0;
  }

  .auth-modal__button:hover {
    background-color: var(--primary-black);
    color: var(--bg-text-gray);
    cursor: pointer;
  }

  .auth-modal__input {
    border: none;
    border-bottom: 1px solid rgba(47, 47, 55, 0.3);
    height: 36px;
    width: 100%;
  }

  .auth-modal__icon {
    cursor: pointer;
    border-bottom: 1px solid rgba(47, 47, 55, 0.3);
  }
  
  .auth-modal__input-container {
    display: flex;
  }

  .auth-modal__input::placeholder {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.5px;
    color: rgba(47, 47, 55, 0.3);
  }
  
  .auth-modal__input:focus {
    outline: none;
  }

  .auth-modal__input:disabled {
    background-color: transparent;
    border-bottom-color: rgba(47, 47, 55, 0.35);
    cursor: not-allowed;
  }
  
  .auth-modal__input-label {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  .auth-modal__input-error {
    margin-top: 3px;
    font-family: Manrope;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.5px;
    color: rgba(255, 57, 57, 1);
  }

  .auth-modal__resend-container {
    text-align: center;
  }

  .auth-modal__resend-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  @media screen and (max-width: 600px) {
    .auth-modal__form {
      width: 390px;
    }
  }