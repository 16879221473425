.noLeftBottomRadius {
  /* border-bottom-left-radius: 0 !important; */
  display: flex;
  min-width: 300px;
  padding: 42px 32px 40px;
  flex-direction: column;
  /* align-items: flex-start; */
  /* gap: 60px; */
  box-shadow: none;
  border-radius: 20px;
  border: 1px solid rgba(47, 47, 55, 0.3);
  background-color: #F1F2F3;
  
}

.check {
  /* max-width: 1600px; */
  margin: 40px 90px 0;
}

.check__idea {
  /* max-width: 811px; */
  max-width: 75%;
  color: var(--primary-black);
  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 74px;
}

.check__container {
  display: flex;
  align-items: flex-start;
  gap: 120px
}

.check__title {
  color: var(--primary-black);
  font-family: PT Mono;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  text-transform: uppercase;
  margin-top: 31px;
}

.check__links {
  display: flex;
  gap: 8px;
}

.check__image {
  background: url(../../images/Winking-Face.png) no-repeat center;
  margin: 0 auto 30px;
  width: 63px;
  height: 63px;
}

/* .check__description {
  color: var(--primary-black);
  text-align: center;
  font-family: Manrope;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  margin-bottom: 60px;
} */

.check__know {
  color: var(--primary-black);
  text-align: center;
  font-family: Manrope;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin-bottom: 51px;
}

.check__button-know {
  display: flex;
  padding: 17px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 0 12px 12px 12px;
  background: var(--primary-black);
  color: var(--bg-text-gray);
  font-family: PT Mono;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 1;
  padding: 0;
  width: 300px;
  height: 56px;
  margin: auto
}

.check__button-know:hover {
  opacity: 75%;
  transition: opacity 0.5s linear;
  color: var(--bg-text-gray);
}

.check__result {
  color: var(--primary-black);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 12px;
}

.chech__link-item {
  width: 56px;
  height: 56px;
  border-radius: 12px;
  background: var(--primary-black);
}

.chech__link-item:hover {
  animation: move 0.3s ease-in-out forwards;
}

.chech__link {
  border-radius: 12px;
  border: 0;
  background: var(--primary-black);
  padding: 0 15px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.chech__link:hover {
  color: var(--bg-text-gray);
  /* font-size: 15px; */
  /* font-family: PT Mono;
  font-style: normal;
  font-weight: 400;
  line-height: normal; */
}

.check__link-title {
  font-family: PT Mono;
  font-size: 1px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--bg-text-gray);
}

.check__link-title:hover {
  opacity: 1;
}

@keyframes move {
  0% {
    width: 56px;
  }
  25% {
    width: 70px;
  }
  50% {
    width: 84px;
  }
  75% {
    width: 98px;
  }
  100% {
    width: 117px;
  }
}

@media screen and (max-width: 768px) {
  .check {
    margin: 20px 20px 0;
  }
}

@media screen and (max-width: 768px) {
  .check__idea {
    font-size: 16px;
    /* margin-bottom: 150px; */
    margin-bottom: 70px;
  }
}

@media screen and (max-width: 768px) {
  .check__title {
    font-size: 18px;
    width: 100%;
    margin-top: 0;
    /* margin-bottom: 28px; */
  }
}

@media screen and (max-width: 768px) {
  .chech__link-item {
    width: 48px;
    height: 48px;
  }
}

@media screen and (max-width: 768px) {
  .chech__link {
    padding: 0 10px;
  }
}

@media screen and (max-width: 768px) {
  .noLeftBottomRadius {
    padding: 42px 20px 40px;
  }
}

@media screen and (max-width: 768px) {
  .check__description {
    font-size: 32px;
    margin-bottom: 48px;
  }
}

@media screen and (max-width: 768px) {
  .check__know {
    font-size: 28px;
  }
}

@media screen and (max-width: 768px) {
  .check__button-know {
    width: 240px;
    font-size: 16px;
    height: 48px;
  }
}