.modal-confirm {
  background: rgba(0, 0, 0, 0.60);
  /* width: 100%;
  height: 100%; */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* margin-right: -50%;
  transform: translate(-50%, -50%); */
  z-index: 100;
  animation: open-modal 0.3s linear;
}

.modal-confirm__container {
  position: relative;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 0 83px;
  /* gap: 10px; */
  /* background-color: var(--primary-color); */
  background-color: var(--bg-gray-lilac);
  /* width: 299px; */
  width: 506px;
  /* height: 52px; */
  height: 241px;
  border-radius: 20px;
  /* z-index: 100; */
  /* animation: open-modal 0.3s linear; */
}

@keyframes open-modal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


/* .modal-confirm_active {
  opacity: 1;
  transition: opacity 2s linear;
  
} */

modal-confirm__img {
  /* width: 28px;
  height: 28px; */
  /* position: fixed; */

  background: url(../../images/smileconfirm.png) no-repeat center;
  z-index: 200;
  width: 52px;
  height: 52px;
}

.modal-confirm__text {
  color: var(--primary-black);
  font-family: Manrope;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.modal-confirm__close {
  background: url(../../images/FrameX.svg) no-repeat center;
  width: 20px;
  height: 20px;
  border: 0;
  position: absolute;
  z-index: 12;
  top: 20px;
  right: 20px;
  padding: 0;
}

@media screen and (max-width: 768px) {
  .modal-confirm__container {
    padding: 0 32px;
    width: 353px;
    height: 190px;
    gap: 22px;
  }
}

@media screen and (max-width: 768px) {
  .modal-confirm__text {
    font-size: 24px;
  }
}

@media screen and (max-width: 768px) {
  .modal-confirm__img {
    width: 45px;
    height: 45px;
  }
}