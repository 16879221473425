.profile {
    margin-bottom: 130px !important;
}

.profile__label {
    color: gray;
    font-weight: normal;
    font-size: 14px;
}

.transaction_label {
    font-size: 18px;
    margin-bottom: 40px;
}

.transaction__block {
    max-width: 600px;
    min-width: 100px;
}

.transaction__info {
    color: gray;
    font-size: 18px;
    border-bottom: 1px solid gray;
    margin-bottom: 48px;
}

.profile__submit__button {
    font-family: Manrope;
    font-size: 18px;
    font-weight: 400;
    line-height: 22.5px;
    text-align: left;
    margin-top: 40px;
    color: black;
}

.profile__buy__button {
    background-color: var(--primary-color);
    font-family: PT Mono;
    font-size: 18px;
    font-weight: 400;
    line-height: 20.16px;
    text-align: center;
    color: var(--bg-text-gray);
    margin-top: 125px;
    border-radius: 12px;
}

.input[disabled] {
    background-color: transparent;
    border-bottom-color: rgba(47, 47, 55, 0.35);
    cursor: not-allowed;
}

.transaction__string {
    padding-bottom: 12px;
}

.transaction__date {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.5px;
    padding-bottom: 20px;
    color: rgba(47, 47, 55, 0.3);
}

.transaction__type {
    font-family: PT Mono;
    font-size: 18px;
    font-weight: 400;
    line-height: 22.5px;
    color: rgba(47, 47, 55, 0.3);
}