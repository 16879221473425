/* normilize */
@import url(./vendor/normalize.css);
/*fonts*/
@import url(./vendor/fonts.css);


:root {
  /* --text-color: #7e7e7e;
  --s-indent: 26px;
  --gradient-primary: linear-gradient(
    270deg,
    #7916e1 0%,
    #680be0 47.92%,
    #5500de 100%
  ); */
  /* --Primary-White: #fdfdfd; */
  /* --letter-space: 0.5px; */
  /* --Input-Text: #cccccc; */
  --Accent-Purple-Color9: #8259F7;
  /* --Accent-Orange: #ff8f6e; */
  --Primary-Black-Color1: #2F2F37;
  /* --Primary-Accent: #726efa;
  --Primary-Accent-Magenta: #ea248a;
  --Secondary-Accent-Blue-Dark: #352df2;
  --Secondary-Purple-Light: #b2b0ff;
  --Gray-Light: #8e8e8e;
  --Gray-Medium: #7e7e7e;
  --Gray-Dark: #737373;
  --Gray-Additional: #9fa1a5;
  --Card-Peach: #ffb19a;
  --Card-Yellow: #fcd78c;
  --Card-Green: #66d4b9;
  --Card-Blue: #bad8f9;
  --Card-Gray: #dddcdf;
  --Error: #ff2455;
  --Rating: #f1c644;
  --BG-Purple: #f8f8ff; */
}

/* :root {
  background-color: #680be0;
}; */

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
