.about {
    margin-bottom: 130px;
}

.about__title {
    font-family: Manrope;
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    color: var(--primary-black);
}

/* .techs__list {
    display: flex;
    gap: 32px;
    margin-top: 110px;
} */

.about__promo-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    /* grid-template-columns: repeat(4, 1fr); */
    gap: 20px;
    margin-top: 34px;
}

.about__promo-title {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 400;
    line-height: 27.6px;
    color: var(--primary-black);
}

.about__promo-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: end;
    /* width: 300px; */
    height: 380px;
    border: 1px solid var(--primary-black);
    border-radius: 12px;
    padding: 24px;
    cursor: pointer;
    /* position: relative; */
}

.about__promo-image {
    width: 160px;
    position: absolute;
    top: 30px;
    right: 23px; 
}

.about__buy {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-family: Manrope;
    font-size: 18px;
    font-weight: 400;
    line-height: 22.5px;
    color: var(--primary-black);
    margin-top: 24px;
}

.about__buy-info {
    max-width: 620px;
}

.about__button {
    font-family: Manrope;
    background-color: var(--primary-color);
    width: 300px;
    height: 56px;
    border-radius: 12px 0 12px 12px;
    border: none;
    color: var(--bg-text-gray);
    cursor: pointer;
    border: none;
    font-size: 18px;
    font-weight: 400;
    line-height: 20.16px;
    text-align: center;
    padding-top: 18px;
    padding-bottom: 18px;
}

.about__button:hover {
    color: var(--bg-text-gray);
}

@media screen and (max-width: 1000px) {
    .about {
      margin-bottom: 50px;
    }
}

@media screen and (max-width: 1000px) {
    .about__button {
      width: 100%;
    }
}

@media screen and (max-width: 1000px) {
    .about__buy-info {
      margin: 17px 0;
    }
}

@media screen and (max-width: 1000px) {
    .about__title {
      font-size: 23px;
      margin-bottom: 10px;
    }
}

@media screen and (max-width: 768px) {
    .about__promo-item {
      margin: 0 27px;
      height: 260px;
    }
}

@media screen and (max-width: 768px) {
    .about__promo-image {
      width: 100px;
    }
}

@media screen and (max-width: 768px) {
    .about__promo-title {
      font-size: 18px;  
    }
}


/* @media screen and (max-width: 500px) {
    .about__promo-item {
      margin: 0 27px;
      height: 260px;
    }
}

@media screen and (max-width: 500px) {
    .about__promo-image {
      width: 100px;
    }
}

@media screen and (max-width: 500px) {
    .about__promo-title {
      font-size: 18px;  
    }
} */