

@media screen and (min-width: 768) {
    .modal_width {
        min-width: 755px;
        max-height: 100vh;
    }
}

.card-promo__modal_body {
    background-color: transparent;
    display: grid;
    gap: 20px;
    overflow: auto;
    scrollbar-width: none;
}  

.card-promo__modal_body_block {
    padding: 32px 30px 33px 30px;
    border-radius: 16px;
    background-color: #F1F2F3;
    cursor: pointer;
} 

.card-promo__tariff_container {
    width: Fixed (755px)px;
    height: Hug (116px)px;
    padding: 32px 30px 33px 30px;
    gap: 20px;
    border-radius: 16px 0px 0px 0px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
}  

.card-promo__tariff_subtitle {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    margin-top: 4px;
    color: var(--primary-color);
}

.card-promo__tariff_button_buy {
    padding: 18px 0px;
    border-radius: 12px 12px 12px 0;
    min-width: 200px;
    height: 56px;
    background-color: var(--primary-black);
    color: white
}

.card-promo__tariff_button_buy:hover {
    color: white
}

.card-promo__modal_stages_block {
    padding: 16px 20px 16px 20px;
    border-radius: 12px;    
    background-color: var(--bg-text-gray);
    margin-bottom: 8px;
} 

.card-promo__modal_stages_title {
    font-family: Manrope;
    font-size: 14px;
    font-weight: 600;
    line-height: 17.6px;
    width: fit-content;
} 

.card-promo__modal_stages_arrow {
    height: 12px;
    padding-top: 5px;
    padding-left: 10px;
} 

.modal-background {
    backdrop-filter: blur(5px);
    opacity: 50%;
}

.dropdown-up {
    height: 12px;
    /* padding-bottom: 5px;
    padding-right: 10px; */
    transform: rotate(180deg);
}

.card-promo__tariff_title {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
}   

@media screen and (max-width: 768px) {
    .card-promo__tariff_title {
        font-size: 12px;
        font-weight: bold;
    }
    .card-promo__tariff_title_icon {
        width: 10px;
        height: 10px;
    }
  }