.team {
    display: flex;
    align-items: baseline;
    gap: 180px;
    position: relative;
    margin-bottom: 130px;
}

.team__experience {
    margin-top: 100px;
    gap: 80px;
    max-width: 760px;
}

.team__container {
    max-width: 760px;
}

.team__title {
    font-family: Manrope;
    font-size: 40px;
    font-weight: 400;
    line-height: 44.1px;
    color: var(--primary-black);
}

/* .team__description {
    font-family: Manrope;
    font-size: 18px;
    font-weight: 400;
    line-height: 22.5px;
    margin-top: 24px;
    color: var(--primary-black);
} */

.team__person {
    display: flex;
    gap: 50px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.team__person-about {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 400;
    line-height: 30px;
    color: var(--primary-black);
    margin:auto;
}

.team__image {
    border-radius: 10px;
    width: 216px;
    height: 255px;
    
}

/* .team__icon {
    position: absolute;
    top: 10px;
    right: 10px;
} */

/* .team__name {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    margin-top: 24px;
    color: var(--primary-black);
} */

/* .team__post {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: var(--primary-black);
} */

.team__contacts {
    position: sticky;
    right: 100px;
    top: 60px;
}

.team__contacts-title {
    font-family: Manrope;
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
    color: var(--primary-black);
}

.team__contacts-list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 32px;
}

.team__contacts-subtitle {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: var(--primary-black);
    color: rgba(47, 47, 55, 0.3);
}

.team__contacts-data {
    margin-top: 6px;
    font-family: Manrope;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    color: var(--primary-black);
}

.team__social-links {
    display: flex;
    gap: 12px;
}

.team__social-link {
    margin-top: 6px;
}

.team__container-info {
    margin-top: 20px;
}

.team__info-title{
    font-family: Manrope;
    font-size: 16px;
    font-weight: 600;
    line-height: 22.4px;
    color: var(--primary-black);
}

.team__info-description {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    color: var(--primary-black);
}

@media screen and (max-width: 1000px) {
    .team__experience {
        margin-top: 50px;
    }
}

@media screen and (max-width: 1000px) {
    .team__contacts {
        display: none;
    }
}
  
@media screen and (max-width: 1000px) {
    .team__image {
        width: 122px;
        height: 146px;
    }
}

@media screen and (max-width: 1000px) {
    .team__person {
        gap: 15px;
        margin-top: 20px;
        margin-bottom: 20px;
    } 
}
  
@media screen and (max-width: 1000px) {
    .team__person-about {
        font-size: 15px;
        line-height: 22px;
    } 
}

@media screen and (max-width: 1000px) {
    .team__title {
        font-size: 32px;
    } 
}
