.card-promo__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--bg-gray-lilac);
  
  height: 700px;
  border-radius: 20px;
  padding: 20px 20px 25px;
  position: relative;
}

.card-promo__container_active {
  background-color: var(--primary-black);
}

.card-promo__container_large {
  /* min-height: 1145px; */
  height: max-content;
}

.card-promo__sale {
  position: absolute;
  top: -12px;
  right: 20px;
  width: 116px;
  height: 29px;
  background-color: var(--primary-color);
  border-radius: 7px;
  font-family: PT Mono;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  color: var(--bg-text-gray);
  padding: 5px 10px;
}

.card-promo__about-container {
  position: absolute;
  top: -57px;
  right: -50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}

.card-promo__about-text {
  max-width: 166px;
  font-family: PT Mono;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  color: var(--primary-black);
  text-align: center;
}

.card-promo__main {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(47, 47, 55, 0.3);
}

.card-promo__main_active {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.card-promo__title {
  font-family: Manrope;
  font-size: 40px;
  font-weight: 500;
  line-height: 42px;
  color: var(--primary-black);
}

.card-promo__subtitle {
  font-family: PT Mono;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: -0.03em;
  color: rgba(47, 47, 55, 0.7);
}

.card-promo__title_active {
  color: var(--bg-text-gray);
}

.card-promo__subtitle_active {
  color: rgba(255, 255, 255, 0.7);
}

.card-promo__info {
  cursor: pointer;
}

.card-promo__main-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-promo__title-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.card-promo__list {
  padding-top: 28px;
  display: flex;
  flex-direction: column;
}

.card-promo__item {
  font-family: PT Mono;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: -0.03em;
  color: var(--primary-black);
  background: url("../../images/Vectorcheck.svg") 0 5px no-repeat;
  list-style-type: none;
  padding-left: 25px;
  margin-bottom: 20px;
  white-space: pre-wrap;
  }

  .card-promo__item_active {
    color: var(--bg-text-gray);
    background: url("../../images/Vector37.svg") 0 5px no-repeat;
  } 
  
.card-promo__item-info {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  letter-spacing: -0.03em;
  color: var(--primary-black);
  opacity: 70%;
  margin-top: 4px;
}  

.card-promo__item-info_active {
  color: #FFFFFF;
  opacity: 70%;
}

.card-promo__end {
  font-family: PT Mono;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: rgba(47, 47, 55, 0.7);
}

.card-promo__end_active {
  color: rgba(255, 255, 255, 0.7);
}

.card-promo__price {
  font-family: Manrope;
  font-size: 36px;
  font-weight: 400;
  line-height: 37.8px;
  color: var(--primary-black);
}

.card-promo__price_active {
  color: var(--bg-text-gray);
}

.card-promo__button {
  width: 184px;
  height: 56px;
  border-radius: 12px 12px 12px 0;
  border: none;
  background-color: var(--primary-black);
  color: var(--bg-text-gray);
  font-family: PT Mono;
  font-size: 18px;
  font-weight: 400;
  line-height: 20.16px;
  text-align: center;
  cursor: pointer;
}

.card-promo__button_active {
  background-color: var(--primary-color);
}

.card-promo__end-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.card-promo__end-buy {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-promo__dropdown-button {
  border: none;
  background-color: transparent;
}

.card-promo__dropdown-button_card_middle {
  padding-left: 6px;
  color: var(--bg-text-gray);
}

.card-promo__dropdown-button_card_min {
  margin-top: 12px;
  color: rgba(47, 47, 55, 1);
}

.card-promo__dropdown-button_active {
  color: rgba(247, 247, 248, 1);
}

.card-promo__dropdown-icon_type_up {
  margin-left: 8px;
  width: 11px;
  height: 8px;
  transform: rotateX(0);
  transition: all 0.2s ease-in;
}

.card-promo__dropdown-icon_type_down {
  transform: rotateX(180deg);
  transition: all 0.2s ease-in;
}

.card-promo__dropdown-list {
  z-index: 10;
  display: flex;
  gap: 12px;
  flex-direction: column;
  padding: 0;
}

.card-promo__dropdown-list_card_middle {
  margin: 15px 0 160px 33px;
}

.card-promo__dropdown-list_card_min {
  margin: 15px 0 17px 33px;
}

.card-promo__dropdown-item {
  font-family: PT Mono;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: -0.03em;
}

.card-promo__dropdown-item_color_white {
  color: var(--bg-text-gray);
}

.card-promo__dropdown-item_color_black {
  color: var(--primary-black);
}

@keyframes scaleMax {
  from {
    width: 24px;
  }
  to {
    width: 78px;
  }
}

@media screen and (max-width: 700px) {
  .card-promo__about-container {
    display: none;
  }  
}

@media screen and (max-width: 780px) {
  .card-promo__title {
    font-size: 32px;
  }  
}

@media screen and (max-width: 900px) {
  .card-promo__button {
    width: 150px;
    height: 50px;
  }  
}

@media screen and (max-width: 900px) {
  .card-promo__price {
    font-size: 32px;
  }  
}