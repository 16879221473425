@font-face {
  /* font-family: "Manrope", "Arial", sans-serif; */
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./Manrope-Regular.woff2) format("woff2"),
    url(./Manrope-Regular.woff) format("woff");
}

@font-face {
  /* font-family: "Manrope", "Arial", sans-serif; */
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(./Manrope-Medium.woff2) format("woff2"),
    url(./Manrope-Medium.woff) format("woff");
}

/* @font-face {
  font-family: "Manrope", "Arial", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(./Inter-Black.woff2) format("woff2"),
    url(./Inter-Black.woff) format("woff");
} */