.tooltip {
  border-radius: 12px 12px 0px 12px;
  padding: 16px 20px;
  position: absolute;
  top: -186px;
  left: -135px;
  width: 285px;
  border: 1px solid var(--primary-black);
  background: #F1F2F3;
  white-space: normal;
  text-align: justify;
}

.tooltip__text {
  color: var(--primary-black);
  font-family: "PT Mono";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.tooltip__span {
  color: var(--primary-color);
  font-weight: 700;
}

@media screen and (max-width: 1400px) {
  .tooltip {
    top: -186px;
    left: 148px;
    border-radius: 12px 12px 12px 0px;
  }
}

@media screen and (max-width: 1000px) {
  .tooltip {
    top: -170px;
    left: -135px;
    border-radius: 12px 12px 0px 12px;
  }
}

@media screen and (max-width: 500px) {
  .tooltip {
    width: 352px;
    left: -201px;
    top: 29px;
    border-radius: 12px 0 12px 12px;
  }
}
