.footer {
    padding: 60px 90px;
    background-color: var(--bg-gray-lilac);
}

.footer__copyright {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: rgba(47, 47, 55, 0.3);
}

.footer__main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer__logo {
    width: 219px;
    height: 61px;
}

.footer__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer__nav {
    display: flex;
    flex-direction: column;
    gap: 32px;
    list-style: none;
}

.footer__nav-link {
    font-family: Manrope;
    font-size: 18px;
    font-weight: 400;
    line-height: 22.5px;
    color: var(--primary-black);
}

.footer__section_nav-link {
    gap: 32px;
    display: grid;
}

.footer__nav-info {
    display: flex;
    gap: 32px;
    list-style: none;
}

.footer__nav-info-link {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: rgba(47, 47, 55, 0.3);
}

.footer__social-links {
    display: flex;
    gap: 12px;
}