.partners {
    margin-top: 100px;
}

.partners__title {
    font-family: Manrope;
    font-size: 42px;
    font-weight: 400;
    line-height: 44.1px;
}

.partners__subtitle {
    font-family: Manrope;
    font-size: 18px;
    font-weight: 400;
    line-height: 22.5px;
    margin-top: 24px;
}

.partners__image {
    margin-top: 40px;
    height: 72px;
}