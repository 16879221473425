.documents_section {
    border: 2px solid #DCE0EC;
    border-radius: 8px;
    width: max-content;
}

@media screen and (max-width: 460px) {
    .documents_section {
        width: auto;
    }
}