.main__form-container {
  margin-bottom: 130px;
}

.main__form {
  display: flex;
  gap: 20px;
  align-items: end;
}

.main__input {
  height: 51px;
  background: transparent;
  border-bottom: 1px solid rgba(47, 47, 55, 0.35);
  box-shadow: none;
  border-radius: 0;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  padding: 12px 0;
  font-family: Manrope;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.main__input:focus {
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: rgba(47, 47, 55, 0.35);
  box-shadow: none;
}

.main__input:hover {
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: rgba(47, 47, 55, 0.35);
}


.main__input::placeholder {
  color: rgba(47, 47, 55, 0.35);
  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.main__button-submit {
  /* width: 300px; */
  width: 380px;
  height: 56px;
  padding: 17px 0px;
  border-radius: 12px 12px 12px 0px;
  /* background-color: var(--primary-color) !important; */
  background-color: var(--primary-black) !important;
  color: var(--bg-text-gray);
  text-align: center;
  font-family: PT Mono;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 0;
  opacity: 1;
  position: relative;
}

.main__question {
  position: absolute;
  left: -172px;
  /* right: 340px; */
  color: var(--primary-black);
  font-family: "PT Mono";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  gap: 7px;
  align-items: center;
  border-left: 1px Solid rgba(47, 47, 55, 0.35);
}

.main__icon {
  width: 24px;
  height: 24px;
}

.main__button-submit:disabled {
  /* background-color: var(--primary-color) !important; */
  background-color: var(--primary-black) !important;
  cursor: auto;
  opacity: 1;
}

.main__button-submit:hover {
  color: var(--bg-text-gray);
}

@media screen and (max-width: 1000px) {
  .main__form {
    flex-direction: column;
    align-items: start
  }
}

@media screen and (max-width: 1000px) {
  .main__button-submit {
    width: 100%;
    border-radius: 0px 12px 12px 12px;
    height: 48px;
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {
  .main__input::placeholder {
    font-size: 20px;
  }
}

@media screen and (max-width: 1000px) {
  .main__form-container {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 1000px) {
  .main__question {
    left: auto;
    right: 0;
    top: -50px;
    border-left: none;
  }
}

@media screen and (max-width: 500px) {
  .main__question {
    right: 0;
    top: -195px;
    border-left: none;
  }
}