.techs__list {
    display: flex;
    gap: 32px;
}

.techs__item {
    display: flex;
    flex-direction: column;
    width: calc(100%/3);
    gap: 20px;
}

.techs__image {
    width: 40px;
    height: 40px;
}

.techs__title {
    font-family: Manrope;
    font-size: 24px;
    font-weight: 500;
    line-height: 25.2px;
}

.techs__description {
    font-family: Manrope;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
}

@media screen and (max-width: 1000px) {
    .techs__list {
      flex-direction: column;
      gap: 20px;
    }
}

@media screen and (max-width: 1000px) {
    .techs__item{
      flex-direction: column;
      width: 100%;
      gap: 15px;
    }
}  
