.subscription__promocard__text {
    display: flex;
    flex-direction: column;
    gap: 11px;
    padding-top: 25px;
    font-family: PT Mono;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    color: var(--primary-black);
}

.subscription__promocard {
    border-radius: 16px;
    background-color: var(--bg-gray-lilac);
    padding: 20px;
}

.subscription__promocard__title {
    font-family: Manrope;
    font-size: 40px;
    font-weight: 500;
    line-height: 42px;
    text-align: left;
    color: var(--primary-black);
}

.subscription__promocard-button {
    font-size: 18px;
    font-weight: 400;
    line-height: 20.16px;
    border: 0;
    padding: 0;
    border-radius: 0 12px 12px 12px;
    background-color: var(--primary-black);
    color: var(--bg-text-gray);
    margin-top: 9px;
    /* width: 276px; */
    height: 56px;
}

.subscription__promocard-button:hover {
    color: var(--bg-text-gray);
}

