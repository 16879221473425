.admin {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 50px 50px 0
}

.admin__title {
  font-family: Manrope;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 60px;
}

.admin__container {
  display: flex;
  gap: 15px;
}

.admin__button {
  max-width: 380px;
  height: 36px;
  border-radius: 10px 10px 10px 0px;
  background-color: var(--primary-color);
  color: var(--bg-text-gray);
  text-align: center;
  font-family: PT Mono;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 0;
}

.admin__promo-title {

}

.admin__count {

}