:root {
  min-height: 100vh;
  background-color: #F1F2F3;
  background: url(../../images/Frame.svg) repeat;
  --custom-link-background-color: transparent; /* Кастомный цвет фона */
  --primary-color: #8259f7;
  --primary-black: #2F2F37;
  --bg-text-gray: #F7F7F8;
  --bg-gray-lilac: #DCE0EC;
  --negative: #FF3939;
  --positive: #37E37C;
  
}

.navbar-item.link {
  background-color: var(--custom-link-background-color) !important;
  color: var(--primary-color) !important;
}

.has-text-primary {
  color: var(--primary-color) !important;
}

/* .has-text-black {
  color: var(--primary-black) !important;
} */

.has-background-primary {
  background-color: transparent !important;;
}

.page {
  max-width: 1600px;
  height: 100vh;
  font-family: "Manrope", Arial, sans-serif;
  background-color: #F1F2F3;
  background: url(../../images/Frame.svg) repeat;
  margin: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  /* z-index: -2; */
}

html {
  scroll-behavior: smooth;
}

.slick-dots li button:before, .slick-next:before, .slick-prev:before {
  color: var(--primary-color) !important;
}

.navbar-burger:hover {
  background-color: transparent;
}

.navbar-link.is-active, 
.navbar-link:focus, 
.navbar-link:focus-within, 
.navbar-link:hover, 
a.navbar-item.is-active, 
a.navbar-item:focus, 
a.navbar-item:focus-within, 
a.navbar-item:hover {
  color: #4a4a4a;
}

.slick-prev {
  left: -5px !important;
}

.slick-next {
    right: -5px !important; 
}